export const CFDI_RELATED_TYPES: { [key: string]: { description: string } } = {
  '01': {
    description: 'Nota de crédito de los documentos relacionados'
  },
  '02': {
    description: 'Nota de débito de los documentos relacionados'
  },
  '03': {
    description: 'Devolución de mercancía sobre facturas o traslados previos'
  },
  '04': {
    description: 'Sustitución de los CFDI previos'
  },
  '05': {
    description: 'Traslados de mercancias facturados previamente'
  },
  '06': {
    description: 'Factura generada por los traslados previos'
  },
  '07': {
    description: 'CFDI por aplicación de anticipo'
  }
}
